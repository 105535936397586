export default [
  {
    title: 'Ana Sayfa',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Teslimatlar',
    route: 'teslim',
    icon: 'TrelloIcon',
  },
  // {
  //   title: 'Emanetler',
  //   route: 'emanet',
  //   icon: 'RefreshCcwIcon',
  // },
  // {
  //   title: 'Kötü Kullanım',
  //   route: 'kotu',
  //   icon: 'AlertTriangleIcon',
  // },
  {
    title: 'Fiyatlar',
    route: 'fiyat',
    icon: 'ListIcon',
  },
  // {
  //   title: 'Hesap Özeti',
  //   route: 'hesap',
  //   icon: 'CommandIcon',
  // },
  // {
  //   title: 'Mutabakat',
  //   route: 'mutabakat',
  //   icon: 'DatabaseIcon',
  // },
]
