<template>
  <b-nav-item-dropdown
      class="dropdown-cart mr-25"
      menu-class="dropdown-menu-media"
      right

  >
    <template #button-content>
      <feather-icon :badge="items.length" class="text-body" icon="ShoppingCartIcon" size="21"/>
    </template>
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Sepetim
        </h4>
        <b-badge pill variant="light-primary"></b-badge>
        {{ items.length }} Ürün
      </div>
    </li>
    <vue-perfect-scrollbar
        v-if="items.length"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
    >
      <b-media
          v-for="item in items"
          :key="item.urunAdi"
      >

        <template #aside>
          <b-img
              :src="item.urunLink"
              :alt="item.urunAdi"
              rounded
              width="30px"
          />
        </template>
        <feather-icon
            icon="XIcon"
            class="cart-item-remove cursor-pointer"
            @click.stop="removeItemFromCart(item)"
        />
        <div class="media-heading">
          <h6 class="cart-item-title">
            <b-link class="text-body">
              {{ item.urunAdi }}
            </b-link>
          </h6>
        </div>

        <div class="cart-item-qty mr-2">
          <b-form-spinbutton @change="degisti(item)"
                             v-model="item.urunMiktari"
                             :min="item.minimumMiktar"
                             size="sm"
          />
        </div>
        <h5 class="cart-item-price">
          {{ item.urunToplamTl }}
        </h5>
      </b-media>
    </vue-perfect-scrollbar>
    <li v-if="items.length" class="dropdown-menu-footer">
      <div class="d-flex justify-content-between mb-1">
        <h6 class="font-weight-bolder mb-0">
          Toplam:
        </h6>
        <h6 class="text-primary font-weight-bolder mb-0">
          {{ items[items.length-1].toplamTl }}
        </h6>
      </div>
      <b-button @click="onayla" variant="primary" block>
        Sepeti Onayla
      </b-button>
    </li>

    <p v-if="!items.length" class="m-0 p-1 text-center">
      Sepetiniz Boş
    </p>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BImg, BFormSpinbutton, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import store from "@/store";
import {dangerToast, successToast} from "@/views/Services/ToastServices";
import {getItems} from "@/views/Services/CartUpdate";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BImg,
    BFormSpinbutton,
    VuePerfectScrollbar,
    BButton,
  },
  directives: {
    Ripple,
  },

  computed: {
    veri: {
      get() {
        return store.getters['loginStoreModule/getUserBilgileri']
      },
    },
    items: {
      get() {
        return store.getters['cart/getCart']
      },
    }
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  methods: {
    async degisti(val) {
      let gonder = await this.$http.post("SepetGuncelleV1", {
        "Id": this.veri.guid,
        "UrunId": val.urunId,
        "UrunMiktari": val.urunMiktari
      });
      if (gonder.status == 200 && gonder.data == true) successToast(this, "Başarılı", "Sepetiniz başarı ile güncellenmiştir")
      else dangerToast(this, "Hata", "Sepetiniz güncellenirken hata alındı, lütfen tekrar deneyin.")
      await getItems(this.veri.guid);
    },
    async onayla() {
      try {
        let veri = await this.$http.get("SepetOnaylaV1/" + this.veri.guid)
        if (veri.status == 200 && veri.data == true) successToast(this, "Başarılı", "Siparişiniz bize ulaştı")
        else dangerToast(this, "Hata", "Sepetiniz onaylanırken hata ile karşılaşıldı, lütfen tekrar denermisiniz.")
        await getItems(this.veri.guid);
      } catch (e) {
        dangerToast(this, "Hata", "İçsel bir hata oluştu")
      }
    },
    async removeItemFromCart(val) {
      try {
        let gonder = await this.$http.post("SepetenSilV1", {
          "Id": this.veri.guid,
          "UrunId": val.urunId,
          "UrunMiktari": val.urunMiktari
        });
        if (gonder.status == 200 && gonder.data == true) {
          const itemIndex = this.items.findIndex(p => p.urunId === val.urunId)
          this.items.splice(itemIndex, 1)
          successToast(this, "Başarılı", "Sepetinizden ürün silidni.")
        } else dangerToast(this, "Hata", "Ürün silinirken hata alındı, lütfen tekrar deneyin.")
        await getItems(this.veri.guid);
      } catch (e) {
        dangerToast(this, "Hata", "İçsel bir hata oluştu")
      }
    },

  }

}
</script>

<style lang="scss" scoped>
.dropdown-cart {
  .media {
    .media-aside {
      align-items: center;
    }
  }
}
</style>
